// Typography
//––––––––––––––––––––––––––––––––––––––––––––––––––

@font-face {
  font-family: 'Nunito';
  src: url('/assets/fonts/Nunito-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  // font-display: swap;
}

@font-face {
  font-family: 'Nunito-light';
  src: url('/assets/fonts/Nunito-Light.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito-bold';
  src: url('/assets/fonts/Nunito-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito-bolder';
  src: url('/assets/fonts/Nunito-Bolder.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

h1,
h2,
h3,
h4,
h5 {
  color: $black;
  margin: 0;
}

h1,
.h1 {
  font-size: rem-calc(36px);
  line-height: 44px;
  font-family: $header-font-family;

  @media (max-width: $phablet-min-width-minus) {
    font-size: rem-calc(26px);
    line-height: 32px;
  }
}

h2,
.h2 {
  font-size: rem-calc(32px);
  line-height: 40px;
  font-family: $header-font-family;

  @media (max-width: $phablet-min-width-minus) {
    font-size: rem-calc(24px);
    line-height: 28px;
  }
}

h3,
.h3 {
  font-size: rem-calc(28px);
  line-height: 36px;
  font-family: $header-font-family;

  @media (max-width: $phablet-min-width-minus) {
    font-size: rem-calc(22px);
    line-height: 28px;
  }
}

h4,
.h4 {
  font-size: rem-calc(24px);
  line-height: 32px;
  font-family: $secondary-font-family-bold;

  @media (max-width: $phablet-min-width-minus) {
    font-size: rem-calc(20px);
    line-height: 28px;
  }
}

h5,
.h5 {
  font-size: rem-calc(20px);
  line-height: 28px;
  font-family: $secondary-font-family-bold;

  @media (max-width: $phablet-min-width-minus) {
    font-size: rem-calc(18px);
    line-height: 24px;
  }
}

p {
  margin: 0;
  font-size: rem-calc(18px);
  line-height: 28px;
  font-family: $secondary-font-family;

  @media (max-width: $phablet-min-width-minus) {
    font-size: rem-calc(16px);
    line-height: 24px;
  }

  &.smaller {
    font-size: rem-calc(16px);
    line-height: 24px;

    @media (max-width: $phablet-min-width-minus) {
      font-size: rem-calc(14px);
    }
  }

  &.smallest {
    font-size: rem-calc(14px);
    line-height: 24px;

    @media (max-width: $phablet-min-width-minus) {
      font-size: rem-calc(12px);
      line-height: 20px;
    }
  }

  &.caption {
    font-size: rem-calc(12px) !important;
    line-height: 20px !important;
    font-family: $base-font-family !important;
  }

  &.label {
    font-size: rem-calc(12px) !important;
    line-height: 12px !important;
  }

  &.footer {
    font-size: rem-calc(10px) !important;
    line-height: 16px !important;
  }

}

a,
button {
  font-size: rem-calc(18px);
  line-height: 18px;
  font-family: $base-font-family-bold;

  @media (max-width: $phablet-min-width-minus) {
    font-size: rem-calc(16px);
    line-height: 16px;
  }
}

// Pre
pre {
  line-height: 1.6;
  margin: 8px 16px;
  white-space: pre-wrap;
}

// Text format helpers
.text-italic {
  font-style: italic;
}

.text-semibold {
  font-weight: 600;
}
.text-light {
  font-weight: 300;
}

.text-bold,
strong {
  font-weight: 700;
}

.text-strike {
  text-decoration: line-through;
}

.text-super {
  vertical-align: super;
}

.text-sub {
  vertical-align: sub;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase {
  text-transform: uppercase;
}

// Text align helpers
.text-left {
  text-align: left;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right;
}

.primary-font {
  font-family: $base-font-family !important;
  font-weight: 400;
}

.primary-font-light {
  font-family: $base-font-family-light !important;
  font-weight: 300;
}

.primary-font-bold {
  font-family: $base-font-family-bold !important;
  font-weight: 700;
}

.primary-font-bolder {
  font-family: $base-font-family-bolder !important;
  font-weight: 800;
}

.secondary-font-light {
  font-family: $secondary-font-family !important;
  font-weight: 300;
}

.secondary-font {
  font-family: $secondary-font-family !important;
  font-weight: normal;
}

.secondary-font-bold {
  font-family: $secondary-font-family-bold !important;
  font-weight: 700;
}

.base-font-bold {
  font-family: $base-font-family-bold !important;
  font-weight: 700;
}

.text-color-primary {
  color: $primary-color;

  &:hover {
    color: $primary-color;
  }
}

.text-color-secondary {
  color: $secondary-color;

  &:hover {
    color: $secondary-color;
  }
}

.text-color-thridly {
  color: $third-color;

  &:hover {
    color: $third-color;
  }
}

.text-color-tertiary {
  color: $tertiary-color;

  &:hover {
    color: $tertiary-color;
  }
}
.text-color-alert {
  color: $alert;
}

.text-color-warning {
  color: $warning;
}

.text-color-success {
  color: $success;
}