$tooltip-bg-color: #CFADD6;

.tooltip {
    position: relative;
    display: inline-block;
}

.tooltip .tooltiptext {
    box-shadow: 0px 4px 14px 0px rgba(87, 35, 98, 0.06), 0px 5px 16px 0px rgba(56, 56, 56, 0.15), 0px 4px 8px 0px rgba(56, 56, 56, 0.13);
    padding: 8px 12px;
    border-radius: 8px;
    background: $tooltip-bg-color;
    color: white;
    display: none;
    position: absolute;
    z-index: 99;
    flex-direction: column;
    align-items: flex-start;
    min-width: 180px;
    width: max-content;
    max-width: 300px;
    top: 40px;
    left: 50%;
    transform: translate(-50%, 0);

    &.tooltip-position-top {
        bottom: 150%;
        top: initial;
    }

    &.tooltip-position-top::after {
        bottom: initial;
        top: 100%;
        border-color: $tooltip-bg-color transparent transparent transparent;
    }

    &.tooltip-position-right {
        right: initial;
        top: 50%;
        left: 100%;
        transform: translate(0, -50%);
        margin-left: 11px;
    }

    &.tooltip-position-right::after {
        bottom: initial;
        left: initial;
        top: 50%;
        right: 100%;
        border-color: transparent $tooltip-bg-color transparent transparent;
        margin-top: -6px;
        margin-left: initial;
    }

    &.tooltip-position-left {
        left: initial;
        top: 50%;
        right: 100%;
        margin-right: 11px;
        transform: translate(0, -50%);
    }

    &.tooltip-position-left::after {
        bottom: initial;
        left: initial;
        top: 50%;
        left: 100%;
        border-color: transparent transparent transparent $tooltip-bg-color;
        margin-top: -6px;
        margin-left: initial;
    }

    @media (max-width: $phablet-min-width-minus) {
        padding: 4px 8px;
        max-width: 100%;
    }

    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

    @media (max-width: $phablet-min-width-minus) {
        font-size: 14px;
    }

    .tooltip-title {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 2px;
        align-self: stretch;

        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;

        @media (max-width: $phablet-min-width-minus) {
            font-size: 14px;
        }

    }

    .tooltip-description {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;

        @media (max-width: $phablet-min-width-minus) {
            font-size: 14px;
        }
    }

    .tooltip-action {
        display: flex;
        padding: 8px;
        align-items: center;
        color: $primary-color;
        font-family: Nunito;
        font-size: 16px;
        font-style: normal;
        font-weight: 800;
        line-height: 16px;
    }
}

.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -6px;
    border-width: 6px;
    border-style: solid;
    border-color: transparent transparent $tooltip-bg-color transparent;
}

.tooltip:hover .tooltiptext {
    display: flex;
}