.button {
  @include font-size(18px);
  line-height: 18px;
  background-image: none;
  border: 0px solid transparent;
  border-radius: 32px;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-family: $base-font-family-bold;
  font-weight: 800;
  letter-spacing: normal;
  margin: 0;
  max-width: 100%;
  outline: none;
  padding: 12px 24px;
  text-align: center;
  vertical-align: middle;
  word-wrap: break-word;
  -webkit-appearance: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 104px;
  min-height: 44px;
  background-color: transparent;
  @media (max-width: $phablet-min-width-minus) {
    font-size: 16px;
    line-height: 16px;
    padding: 12px 24px;
  }

  i {
    font-size: 21px;
    line-height: 60px;
  }

  a {
    font-family: $header-font-family;
    color: white;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }

    &:focus {
      outline: 0;
    }
  }

  @include button-theme($text-color);

  &.primary {
    @include button-theme($primary-color);
  }
  &.primary:focus {
    @include button-theme($primary-color-dark);
  }
  &.primary:hover {
    box-shadow: 0px 2px 2px 0px rgba(52, 15, 60, 0.40);
  }
  &.primary:disabled {
    @include button-theme($white-grey);
    color: $disabled-grey;
    box-shadow: none;
  }
  &.secondary {
    @include button-theme(#ffffff);
    border: 1px solid $primary-color;
    color: $primary-color;
  }
  &.secondary:hover {
    border: 1px solid $primary-color;
    @include button-theme($primary-color-hover);
    box-shadow: 0px 2px 2px 0px rgba(87, 35, 98, 0.40);
    border-color:$primary-color;
  }
  &.secondary:focus {
    @include button-theme($primary-color-light);
    border-color:$primary-color;
  }
  &.secondary:disabled {
    border: 1px solid $disabled-grey;
    @include button-theme($white-grey);
    color: $disabled-grey;
    box-shadow: none;
    border-color:$disabled-grey;
  }
  &.tertiary {
    border: none;
    color: $primary-color;
    background-color: transparent;
    font-family: $base-font-family;
  }
  &.tertiary:hover {
    color: $primary-color-mid;
  }
  &.tertiary:focus {
    color: $tertiary-color;
  }
  &.tertiary:disabled {
    border: none;
    color: $disabled-grey;
    box-shadow: none;
  }
  &.transparent {
    border: 1px solid $primary-color;
    background-color: transparent;
    color: $primary-color;
  }
  &.transparent:hover {
    color: $primary-color-mid;
  }
  &.transparent:focus {
    color: $tertiary-color;
  }
  &.transparent:disabled {
    border: none;
    color: $disabled-grey;
    box-shadow: none;
  }
  &.disabled {
    @include button-theme($white-grey);
    color: $disabled-grey;
  }
}

input {

  &[type="submit"],
  &[type="reset"],
  &[type="button"] {
    display: inline-block;
    padding: 15px 30px;
    color: white;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.1rem;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    border-radius: 50px;
    border: 1px solid $border-color;
    cursor: pointer;
    box-sizing: border-box;
  }
}

input {

  &[type="submit"]:hover,
  &[type="reset"]:hover,
  &[type="button"]:hover {
    color: white;
    border-color: lighten($dark-grey, 33.3%);
    outline: 0;
  }
}

input {

  &[type="submit"]:focus,
  &[type="reset"]:focus,
  &[type="button"]:focus {
    color: white;
    border-color: lighten($dark-grey, 33.3%);
    outline: 0;
  }
}

button[disabled] {
  opacity: 0.7;
  cursor: not-allowed;
}
