// Breakpoints
$larger-than-mobile: "min-width: 426px" !default;
$larger-than-phablet: "min-width: 640px" !default;
$larger-than-phablet-plus-one: "min-width: 641px" !default;
$larger-than-tablet: "min-width: 750px" !default;
$larger-than-desktop: "min-width: 1025px" !default;
$larger-than-desktophd: "min-width: 1200px" !default;
$desktop-wide-screen: "1920px";
$desktop-max-width-minus: "1439.9px";
$desktop-1399-break-point: "1399.9px";
$desktop-1184-break-point: "1184.9px";
$old-mobile-min: "320px";
$mobile-min: "426px";
$phablet-min-width: "640px";
$phablet-min-width-plus-one: "641px";
$phablet-min-width-minus-one: "639px";
$phablet-min-width-minus: "639.9px";
$smaller-than-ipad: "max-width: 832.9px" !default;
$mobile-min-width-minus: "414px";
$desktop-min-width-minus: "1024.9px";
$tablet-min-width: "751px";
$tablet-min-width-minus: "749.9px";
$ipad-min-width: "835px";
$ipad-min-width-minus: "833.9px";
$desktop-min-width: "1025px";
$desktophd-min-width: "1200px";
$iphone-max-width: "475.9px";
// Responsive Grid System
$grid__bp-sm: 426;
$grid__bp-md: 640;
$grid__bp-lg: 750;
$grid__bp-xl: 1025;
$grid__bp-max: 1360;
$grid__cols: 12;

// Colors
$white-grey: #ebebeb !default;
$lightest-grey: #e2e2e2 !default;
$light-grey: #f2f2f2 !default;
$grey: #B1B1B1 !default;
$medium-grey: #999999 !default;
$dark-grey: #666666 !default;
$darkest-grey: #333333 !default;
$disabled-grey: #727272 !default;
$grey-transparent-02: #38383805 !default;
$grey-transparent-04: #38383812 !default;
$grey-transparent-06: #3838380f !default;
$grey-transparent-07: #3838380a !default;
$black: #383838 !default;
$blue: #7cbadb !default;

$primary-color: #863399 !default;
$primary-color-dark: #722E81 !default;
$primary-color-light: #F3EBF5 !default;
$primary-color-hover: #F9F5FA !default;
$primary-color-mid: #B965CB;
$primary-opacity-20: #E7D6EB;
$secondary-color: #f58520 !default;
$secondary-color-dark: #404C52 !default;
$secondary-opacity-5: #FFF9F4 !default;
$secondary-opacity-10: #FEF3E9 !default;
$secondary-opacity-20: #FDE7D2 !default;
$secondary-light: #FFF6EE !default;
$third-color: #fc9c46 !default;
$third-color-dark: #c16e3c !default;
$third-color-400: #FF674D !default;
$tertiary-color: #572362;
$tertiary-light: #FAE3FE !default; 
/* todo change*/

$menu-bar-bg: #dbd5fa !default;
$menu-bar-font: #000000 !default;
$menu-bar-font-alt: #ffffff !default;

$border-color: #979797 !default;
$link-color: $primary-color !default;
$text-color: $black !default;
$text-color-light: $medium-grey !default;
$input-border: #C6C6C6 !default;
$grayscale-Opacity-Steel-5: #FBFAFA !default;
$grayscale-Opacity-Steel-20: #EDEDED !default;
$grayscale-Shade-50: #F5F5F5 !default;
$grayscale-Shade-100: #DDDDDD !default;
$grayscale-Shade-400: #9B9B9B !default;
$grayscale-Shade-800: #4B4B4B !default;

$footer-bg-light: #e3e1e3 !default;
$footer-bg-alt-light: #c3c1c3 !default;
$footer-bg: #042025 !default;
$footer-bg-alt: #446369 !default;
$header-bg: #7a888d !default;

$notification-bg: #fc9c46 !default;
$notification-bg-alt: #beb4f8 !default;
$notification-bg-critical: $primary-color !default;
$notification-bg-critical-alt: $menu-bar-bg !default;
$grayscale-Shade-800:#4B4B4B !default;

$success: #0EB864 !default;
$warning: #f5a623 !default;
$alert: #D94A47 !default;

$green: #2BB165 !default;
$red: #d0021b !default;
$light-red: #D22119 !default;
$red-800: #9B0308 !default;
$blue: #0064c9 !default;
$dark-blue: #0751FC !default;
$darker-blue: #0000DB !default;
$turquoise: #07BDFA !default;
$light-turquoise: #0DC0FA !default;
$orange: #F05C41 !default;
$light-orange: #C88683 !default;
$pink: #ffd7e0 !default;
$pink-dark: #F30F62 !default;

// Google Fonts Import
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700,800);
@import url(https://fonts.googleapis.com/css2?family=Baloo+Bhaijaan+2:wght@400..800&family=Mochiy+Pop+One&display=swap);

// Font weights
$light: 300;
$regular: 400;
$bold: 700;

// Base Font
$base-font-family: "Nunito", "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$base-font-family-light: "Nunito-light", "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$base-font-family-bold: "Nunito-bold", "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$base-font-family-bolder: "Nunito-bolder", "Helvetica Neue", Helvetica, Arial, sans-serif !default;

$base-font-size: 14px;
$base-line-height: 1.4;

$secondary-font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$secondary-font-family-bold: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$cookies-font-family: "cookiesAndCream", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$proxima-font-family: "proxima-nova", sans-serif !default;
$lato-font-family: "Lato", sans-serif !default;
$road-rage-family:  "Road Rage", sans-serif;
$damion-font-family: "Damion", cursive;
$petit-font-family: "Petit Formal Script", cursive;
$baloo-bhaijaan-family: "Baloo Bhaijaan 2", serif;
// Fixed Font
$fixed-font-family: monospace;
$fixed-font-size: 85%;
$fixed-line-height: $base-line-height;

// Headings
$header-font-family: "Nunito-bold", "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$header-font-weight: $bold;

// Buttons
$button-font-family: "Nunito-bold", "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$button-font-weight: $bold;

// Nav
$nav-font-family: "Nunito-bold", "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$nav-font-weight: $bold;

// Misc
$global-radius: 1px !default;
$assets-path: "/assets/img/" !default;

$sg-purple: #863399;
$sg-lavendar: #E2E2E2;
$sg-steel: #a5a3a3;
$sg-white: #FFFFFF;
$sg-orange: #F58520;
$sg-charcoal: #2a3638;
$sg-grey: #E2E2E2;
$sg-light-grey: #EAEAEA;
$light-blue: #F2FAFC;

$grey: #dbd8d8;
$disabled-grey: #959595;
$light-charcoal: #848484;

//proxima-nova font Import from Adobe
@import url(https://use.typekit.net/fve0ycq.css);