.banner-slider-mobile {
  display: inline-block;
  height: 435px;
  background-image: url(/assets/img/banners/slider-mobile.png);
  background-position: top;
  background-size: cover;
  margin-top: 120px;

  @media (#{$larger-than-tablet}) {
    display: none;
  }

  .banner {
    background-color: transparent;
    text-align: center;
    padding: 100px 0 15px 0;

    @media (orientation: landscape) {
      text-align: left;
    }

    .banner-content-mobile {
      padding: 0 15px;
      font-size: rem-calc(17px);

      h1 {
        font-size: 26px;
        margin-bottom: 5px;
        margin-top: -125px;
        line-height: 0.96;

        @media (orientation: landscape) {
          margin-bottom: 0.3em;
        }
      }

      .pitch {
        font-family: $base-font-family;
        font-size: rem-calc(17px);
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.47;
        letter-spacing: normal;
        text-align: center;
        margin-bottom: 27px;
        @media (orientation: landscape) {
          margin-bottom: 10px;
        }
      }

      .description {
        font-family: $base-font-family;
        color: $black;
        font-size: rem-calc(17px);
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.29;
        letter-spacing: normal;
        text-align: center;
        margin-bottom: 25px;

        @media (orientation: landscape) {
          margin-bottom: 1em;
          max-width: 60%;
        }
      }

      a {
        .button {
          background-color: $secondary-color;
          margin-bottom: 2em;
          width: 216px;
          @media (orientation: landscape) {
            margin-bottom: 1.4em;
          }
        }

        @media (orientation: landscape) {
          width: 70%;
          float: left;
        }
      }

      .banner-disclaimer {
        font-size: 0.5em;
        font-family: $base-font-family-bold;

        @media (orientation: landscape) {
          width: 30%;
          margin-top: 5px;
          float: left;
        }
      }
    }
  }
}

.banner {
  background-color: white;
  background-position: top center;
  background-size: cover;
  width: 100%;
  margin-top: 5px;

  .eight,
  .seven,
  .five {
    padding-left: 0px;
    padding-right: 0px;
  }

  .offset-two {
    margin-left: 8.333333333333332%;

    @media (#{$larger-than-phablet}) {
      margin-left: 16.666666666666664%;
    }
  }

  &.us-largest-network {
    .container,
    .row,
    .twelve {
      height: 100%;
    }

    color: white;

    height: 455px;
    background-color: white;
    background-image: url("/assets/img/banners/map.png");
    background-repeat: no-repeat;
    background-size: 247.1px 166.8px;
    background-position-y: 165px;
    padding: 0;

    @media (#{$larger-than-phablet}) {
      height: 630px;
      background-size: contain;
      background-position: center;
      background-position-y: center;
      background-color: rgba(242, 242, 242, 0.51);
    }

    @media (orientation: landscape) {
      height: 630px;
      min-height: 400px;
    }

    font-size: rem-calc(22px);

    .banner-content {
      margin-right: auto;
      padding: 0px;
      text-align: center;
      color: $black;
      h2 {
        color: $black;
        margin-top: 25px;
        letter-spacing: normal;
        text-align: center;

        @media (#{$larger-than-phablet}) {
          margin-top: -10px;
          margin-bottom: 13px;
        }
      }

      .pitch {
        color: $black;
        font-size: rem-calc(17px);
        @media (#{$larger-than-desktop}) {
          font-size: rem-calc(22px);
        }
      }

      a {
        .button {
          position: absolute;
          bottom: 40px;
          left: calc(50% - 105px);
          margin: 0;

          @media (#{$larger-than-mobile}) {
            bottom: -10px;
            min-width: 166px;
            margin-bottom: 5px;
            left: calc(50% - 83px);
          }

          @media (#{$larger-than-desktop}) {
            bottom: -10px;
            margin-bottom: 5px;
            left: calc(50% - 130px);
          }
        }
      }
    }

    @media (#{$larger-than-phablet}) {
      /*background-size: cover;*/
      background-image: url("/assets/img/banners/map@2x.png");
      @media (orientation: landscape) {
        background-image: url("/assets/img/banners/map@2x.png");
      }
    }

    @media (#{$larger-than-phablet}) {
      background-image: url("/assets/img/banners/map@3x.png");
      background-size: 450px auto;
      padding: 90px 0;
    }
  }

  .banner-content {
    color: $black;

    &.margin-left-fix {
      @media (#{$larger-than-phablet}) {
        padding: 0;
        text-align: left;
        /*margin-left: 15px;*/
        margin-right: 0;
      }
    }

    h1 {
      color: $black;
      font-size: rem-calc(36px);
      font-weight: bold;
      letter-spacing: 1px;
      margin-top: 60px;
      text-transform: none;

      @media (orientation: landscape) {
        margin-top: 130px;
      }

      @media (#{$larger-than-desktop}) {
        font-size: rem-calc(38px);
        letter-spacing: normal;
        margin-top: 244px;
        margin-bottom: 24px;
      }
    }

    .pitch {
      font-family: $base-font-family;
      font-size: rem-calc(38px);
      text-transform: none;
      font-weight: normal;

      @media (#{$larger-than-desktop}) {
        font-size: rem-calc(26px);
      }
    }

    .description {
      color: $black;
      font-weight: normal;
      line-height: 1.16;
      font-size: rem-calc(19px);
      padding-top: 18px;
      letter-spacing: normal;

      &.secondary-description {
        font-size: rem-calc(15px);
        line-height: 0.89;
        margin-bottom: 25px;
        margin-top: 8px;
      }
    }

    .small-pitch {
      font-size: rem-calc(12px);
      line-height: rem-calc(18px);

      @media (#{$larger-than-desktop}) {
        font-size: rem-calc(14px);
        line-height: rem-calc(22px);
      }

      a {
        color: #2e5368;
      }
    }

    .banner-disclaimer {
      font-size: rem-calc(16px);
      color: white;
      margin-top: 50px;
      position: relative;
      @media (#{$larger-than-desktop}) {
        font-size: rem-calc(16px);
        color: white;
        margin-top: 100px;
      }
    }

    a button {
      margin-top: 15px;
      min-width: 216px;
    }
  }
}

.swiper-container-override {
  .slick-dots {
    &.right {
      @media (#{$larger-than-tablet}) {
        text-align: right;
        padding-right: 20%; //385px;
        bottom: 35px;
        left: 0;
      }
    }

    &.left {
      @media (#{$larger-than-tablet}) {
        text-align: left;
        padding-left: 100px;
      }
    }

    &.hallow {
      .swiper-pagination-bullet {
        opacity: 1;
        background-color: transparent;
      }

      li {
        height: 10px;
        width: 10px;
        margin: 10px 5px;

        button {
          content: "";
          height: 10px;
          width: 10px;
          padding: 0px;
          cursor: pointer;
          border: 1px solid #c8c8c8;
          border-radius: 10px;
          &:before {
            width: 13px;
            height: 13px;
          }
        }
        &.slick-active {
          button:before {
            opacity: 1;
          }
        }
        button:before {
          content: "";
        }
        &.swiper-pagination-bullet-active {
          button {
            content: "";
            height: 10px;
            width: 10px;
            padding: 5px;
            cursor: pointer;
            border: 1px solid #c8c8c8;
            border-radius: 10px;
            background-color: #c8c8c8;
          }
          &.slick-active {
            button:before {
              opacity: 1;
              color: #c8c8c8;
            }
          }
          button:before {
            content: "";
          }
        }
      }
    }
  }

  .slick-prev,
  .slick-next {
    top: 400px;
    &:before {
      font-size: 32px;
    }
  }

  .slick-prev {
    /*left: 303px; // 15.8%;*/
    left: auto;
    &:before {
      content: "\e91c";
    }
  }

  .slick-next {
    right: 16.4%; //315px;// 16.4%;
    &:before {
      color: white;
      content: "\e91d";
    }
  }
  &.why-good2go-slider-override {
    .container,
    .row {
      height: 100%;
    }

    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;

    .slick-dots {
      width: 100%;
      position: relative;
      list-style: none;
      display: block;
      text-align: left;
      height: 30px;
      margin-left: 3.5%;
      top: calc(100% - 60px);
      @media (max-width: 1200px) {
        margin-left: 0;
      }
      @media (max-width: 1024px) {
        top: calc(100% - 12px);
        text-align: center;
      }
      @media (max-width: 640px) and (min-width: 320px) {
        top: calc(100% - 20px);
        text-align: center;
        li {
          height: 10px;
          width: 10px;
          margin: 0px 11px;
        }
      }
      li {
        height: 10px;
        width: 10px;
        margin: 0px 8px;

        &.swiper-pagination-bullet {
          bottom: 0;
          background-color: $secondary-color;
          opacity: 1;
        }

        &.swiper-pagination-bullet-active {
          background-color: $primary-color;
        }

        button {
          &:before {
            content: "";
          }
        }
      }
    }

    .slick-prev,
    .slick-next {
      display: none;
    }
  }
  &.featured-plans-slider-override {
    .container,
    .row {
      height: 100%;
    }
    .slick-dots {
      width: 100%;
      position: relative;
      list-style: none;
      display: flex;
      text-align: center;
      height:100%;
      margin-bottom: 28px;
      left: 0;
      top: 0;
      li {
        height: 9px;
        width: 9px;
        margin: 0 8px;

        &.swiper-pagination-bullet {
          bottom: 0;
          background-color: $secondary-color;
          opacity: 1;
        }

        &.swiper-pagination-bullet-active {
          background-color: $primary-color;
        }

        button {
          &:before {
            content: "";
          }
        }
      }
    }
    .container{
      margin-top: 0;
    }
  }
  &.social-media-slider-override {
    .container,
    .row {
      height: 100%;
    }
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;

    .slick-dots {
      width: 100%;
      position: relative;
      list-style: none;
      display: block;
      text-align: left;
      height: 30px;
      left: 150px;
      top: calc(100% - 110px);
      @media (max-width: 1024.9px) {
        top: calc(100% - 197px);
        left: 0;
        display: flex;
        justify-content: center;
      }
      @media (max-width: 749.9px) {
        top: calc(100% - 127px);
        margin: 0;
        left: 0;
    }
      li {
        height: 9px;
        width: 9px;
        margin: 0 8px;

        &.swiper-pagination-bullet {
          bottom: 0;
          background-color: white;
          opacity: 1;
        }

        &.swiper-pagination-bullet-active {
          background-color: $secondary-color;
        }

        button {
          &:before {
            content: "";
          }
        }
      }
    }

    .slick-prev,
    .slick-next {
      position: relative;
      top: 50%;

      opacity: 1;
      border-radius: 25px;
      text-align: center;

      @media (#{$larger-than-tablet}) {
        background-color: $primary-color;
      }

      &:before {
        font-size: 17px;
        color: $black;
        line-height: 40px;
        font-weight: bold;
        @media (#{$larger-than-tablet}) {
          color: white;
        }
      }
    }

    .slick-prev {
      /*left: 303px; // 15.8%;*/
      left: 0;
      float: left;
      &:before {
        content: "\e91c";
      }
    }

    .slick-next {
      right: 0; //315px;// 16.4%;
      float: right;
      &:before {
        content: "\e91d";
      }
    }
  }
}

.social-media-slider {
  //height: 285px;
  max-width: 1155px;
  @media (#{$larger-than-desktop}) {
    height: 340px;
  }
  @media (max-width: 640px) {
    width: 100%;
    text-align: center;
  }

  &.full-content {
    height: auto;
  }
  .social-media-slide {
    .container-flex {
      width: 100%;
      max-width: 1155px;
      padding: 10 0px;
    }
    @media (max-width: 1025px) {
      height: 500px;
    }
    @media (max-width: 639.9px) {
      height: 580px;
    }
  }
}

.featured-plans-slider {
  height: 320px;
  @media (#{$larger-than-phablet}) {
    width: $grid__bp-md * 1px;
  }
  @media (#{$larger-than-tablet}) {
    width: $grid__bp-lg * 1px;
  }
  @media (#{$larger-than-desktop}) {
    width: $grid__bp-xl * 1px;
  }
  @media (#{$larger-than-desktophd}) {
    width: $grid__bp-max * 1px;
  }

  .swiper-slide {
    min-width: 285px;
  }

  .slick-prev,
  .slick-next {
    top: 400px;
    &:before {
      font-size: 32px;
    }
  }

  .slick-prev {
    left: auto;
    &:before {
      content: "\e91c";
    }
  }

  .slick-next {
    right: 16.4%; 
    &:before {
      color: white;
      content: "\e91d";
    }
  }

  .featured-plans-slider-nav {
    position: absolute;

    @media (#{$larger-than-phablet}) {
      width: $grid__bp-md * 1px;
    }
    @media (#{$larger-than-tablet}) {
      width: $grid__bp-lg * 1px;
    }
    @media (#{$larger-than-desktop}) {
      width: $grid__bp-xl * 1px;
    }
    @media (#{$larger-than-desktophd}) {
      width: $grid__bp-max * 1px;
    }

    top: 50%;

    .slick-prev,
    .slick-next {
      position: relative;
      top: 0;

      opacity: 1;
      border-radius: 25px;
      text-align: center;

      @media (#{$larger-than-tablet}) {
        background-color: $primary-color;
      }

      &:before {
        font-size: 17px;
        color: $black;
        line-height: 40px;
        font-weight: bold;
        @media (#{$larger-than-tablet}) {
          color: white;
        }
      }
    }

    .slick-prev {
      left: 0;
      float: left;
      &:before {
        content: "\e91c";
      }
    }

    .slick-next {
      right: 0; 
      float: right;
      &:before {
        content: "\e91d";
      }
    }

    .slider-nav-bg-prev,
    .slider-nav-bg-next {
      background-color: white;
      height: 320px;
      top: -180px;
      z-index: 2;
      position: absolute;
      border-radius: 0;
      width: 110px;

      @media (#{$larger-than-tablet}) {
        width: 130px;
      }

      @media (#{$larger-than-desktop}) {
        width: 160px;
      }
    }

    .slider-nav-bg-prev {
      right: -40px;
    }

    .slider-nav-bg-next {
      left: -40px;
    }
  }
}
